import GeneralDialog from 'dialogs/GeneralDialog'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDialogs } from 'shared/hook'
import { DialogType } from 'store/dialogs.slice'
import Image from 'next/image'
import { getImage } from 'shared/utils'
import { micrositeNavigate } from 'shared/webBridge'
import { useRouter } from 'next/router'

const COUNTDOWN_TIMER = 5
const EndedTimeDialog = () => {
  const router = useRouter()
  const { isOpen, close } = useDialogs(DialogType.EndedTimeDialog)
  const { t } = useTranslation()
  const [second, setSecond] = useState<number>(COUNTDOWN_TIMER)

  useEffect(() => {
    if (!isOpen) return

    const timerId = setInterval(() => {
      setSecond((prevState) => {
        if (prevState === 0) clearInterval(timerId)
        return prevState - 1
      })
    }, 1000)

    return () => {
      if (timerId) clearInterval(timerId)
    }
  }, [isOpen])

  return (
    <GeneralDialog
      onClose={() => {
        close()
      }}
      open={isOpen}
      primaryActionProps={{
        children: t(`Xem kết quả ${second > 0 ? `(${second})` : ``}`),
        disabled: second > 0,
      }}
      secondaryActionText={t('Tải lại')}
      onClickSecondary={() => {
        //* When a slot/event is ended, move selecting slot/event to the next slot/event
        router.reload()
        close()
      }}
      onClickPrimary={() => {
        micrositeNavigate(`${process.env.BASE_URL}/history`)
      }}
      backdropClickable={false}
    >
      <div className="p-2.5 large:p-10 flex justify-center items-center flex-col font-sans text-center large:w-[470px]">
        <div className="relative h-[125px] large:h-[200px] w-[125px] large:w-[200px] mb-2.5 large:mb-10">
          <Image src={getImage('ended-image.png')} alt="" width={200} height={200} />
        </div>
        <p className="font-medium text-normal large:text-2xl large:leading-7 mb-1 large:mb-10">
          {t('Hết thời gian mất rồi')}
        </p>
        <p className=" font-normal text-sm leading-5 large:text-title large:leading-6">
          {t('Tiếc quá! Phiên đoán giá đã kết thúc.')}
          <br />
          {t('Phiên sau bạn nhớ quay lại sớm nha!')}
        </p>
      </div>
    </GeneralDialog>
  )
}

export default memo(EndedTimeDialog)
