import clsx from 'clsx'
import EventTabsSkeleton from 'components/EventTabsSkeleton'
import dayjs from 'dayjs'
import React from 'react'
import { useSelector } from 'react-redux'
import { getEventTabsLabel, timeOffset } from 'shared/utils'
import { RootState, useAppDispatch } from 'store'
import apiSlice from 'store/api.slice'
import { setSelectingEvent } from 'store/app.slice'
import { IGetActivityResponse } from 'types'

interface EventTabsProps {
  data?: IGetActivityResponse
}

const EventTabs = ({ data }: EventTabsProps) => {
  const selectingEvent = useSelector((state: RootState) => state.app.selectingEvent)
  const dispatch = useAppDispatch()
  const currentEventStatus = useSelector((state: RootState) => state.app.currentEventStatus)

  if (!data) return <EventTabsSkeleton />
  const { current_event_code, slots } = data

  return (
    <nav className="flex flex-row bg-white">
      {slots?.map(({ begin_time, end_time, event_code }) => {
        const isActive = event_code === selectingEvent
        return (
          <button
            key={event_code}
            onClick={() => {
              if (event_code === selectingEvent) return

              //* if the currentEventStatus is ended and the user changed the selectingEvent, automatically refetch
              if (currentEventStatus === 'ended') {
                dispatch(apiSlice.util.invalidateTags(['Activity']))
              }

              //* if user select already ended event, move the selecting event to next event
              if (current_event_code === event_code && currentEventStatus === 'ended') {
                dispatch(setSelectingEvent(slots[1]?.event_code))
              } else dispatch(setSelectingEvent(event_code))
            }}
            className={clsx(
              {
                '!border-b-2 large:!border-b-6 !border-primary': isActive,
              },
              'pt-1 large:pt-5 pb-0.5 large:pb-3 flex-1 focus:outline-none border-b border-gray-300 transition',
            )}
          >
            <p
              className={clsx('text-large large:text-4xl large:leading-[44px] mb-1 large:mb-2', {
                'font-medium text-primary': isActive,
              })}
            >
              {dayjs(begin_time - timeOffset).format('HH:mm')}
            </p>
            <p className={`text-foot large:text-2xl ${isActive ? 'font-medium text-primary' : 'text-gray-600'}`}>
              {getEventTabsLabel(begin_time, end_time)}
            </p>
          </button>
        )
      })}
    </nav>
  )
}

export default React.memo(EventTabs)
