const ItemListSkeleton = () => {
  return (
    <div className="flex overflow-x-hidden justify-around p-3 large:py-7 large:px-10">
      {Array.from(Array(2)).map((_, idx) => (
        <div
          key={idx}
          className="bg-skeleton min-w-[170px] h-[298px] large:min-w-[552px] large:h-[808px] animate-pulse mx-1"
        />
      ))}
    </div>
  )
}

export default ItemListSkeleton
