import { memo } from 'react'

const Loop = ({ ...rest }) => {
  return (
    <svg {...rest} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 8.01461C3 9.35813 3.53476 10.6142 4.46017 11.5398L3.75184 12.2482C2.64272 11.1381 2 9.62887 2 8.01656C2 4.70728 4.68555 2.02592 8 2.02592H8.02148L6.98828 1.03906L7.67969 0.3125L9.58594 2.18388C9.78125 2.38083 9.7793 2.70065 9.58008 2.89371L7.60648 4.81422L6.84036 4.11284L8.01367 3.02241H8C5.23828 3.02241 3 5.2572 3 8.01461Z"
        fill="currentColor"
      />
      <path
        d="M13.0001 8.01266C13.0001 6.63171 12.4356 5.34043 11.4574 4.40653L12.1647 3.69928C13.3275 4.81986 14.0001 6.36271 14.0001 8.01266C14.0001 11.1484 11.588 13.7205 8.51771 13.9838L9.62381 14.9737L9.56564 15.0424C9.36993 15.2738 9.17417 15.5052 8.97329 15.7321L6.96888 13.9838C6.75599 13.8044 6.7306 13.4865 6.91224 13.2759L8.73115 11.1582L9.46511 11.7834L8.48451 12.9815C11.0177 12.7396 13.0001 10.6063 13.0001 8.01266Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default memo(Loop)
