import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import Arrow from 'icons/Arrow'
import styles from './CarouselArrows.module.scss'
import { isIOS } from 'shared/webBridge'
import { CARD_WIDTH_WITH_MARGIN } from 'components/ItemList'
import { iOSversion, isLargeScreen } from 'shared/utils'

// The number is followed by design
const CarouselArrows = ({ listLength, onChange }) => {
  const [currentPosition, setCurrentPosition] = useState(0)
  const actualScreenWidth = isLargeScreen() ? 960 : window.innerWidth
  const cardFullOnScreen = Math.floor(actualScreenWidth / CARD_WIDTH_WITH_MARGIN)
  const shouldRightArrowVisible = listLength - currentPosition > cardFullOnScreen
  const shouldLeftArrowVisible = currentPosition !== 0

  useEffect(() => {
    // kickoff smoothscroll polyfill
    if (isIOS() && iOSversion() < 15.4 && shouldRightArrowVisible)
      import('smoothscroll-polyfill').then((smoothscroll) => smoothscroll.polyfill())
  }, [])

  useEffect(() => {
    onChange(currentPosition)
  }, [currentPosition])

  const handlePrevClick = () => {
    setCurrentPosition(Math.max(currentPosition - 1, 0))
  }

  const handleNextClick = () => {
    setCurrentPosition(Math.min(currentPosition + 1, listLength - 1))
  }

  return (
    <>
      <button
        className={clsx(styles['carousel-arrow-container'], { invisible: !shouldLeftArrowVisible })}
        onClick={handlePrevClick}
        aria-label="left-arrow"
      >
        <div className={clsx(styles['carousel-arrow'], 'rotate-180')}>
          <Arrow />
        </div>
      </button>
      <button
        className={clsx(styles['carousel-arrow-container'], 'right-0', { invisible: !shouldRightArrowVisible })}
        onClick={handleNextClick}
        aria-label="right-arrow"
      >
        <div className={clsx(styles['carousel-arrow'])}>
          <Arrow />
        </div>
      </button>
    </>
  )
}

export default React.memo(CarouselArrows)
