import { memo } from 'react'

const ArrowRight = () => {
  return (
    <svg
      className="w-[9px] h-[16px] large:w-[21px] large:h-[40px]"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33329 8.94288L1.47136 15.8048L0.528553 14.862L7.39048 8.00007L0.528554 1.13814L1.47136 0.19533L8.33329 7.05726C8.85399 7.57796 8.85399 8.42218 8.33329 8.94288Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default memo(ArrowRight)
