import CountDownTimer from 'components/CountDownTimer'
import ChancesBar from 'components/ChancesBar'
import React from 'react'
import { useGetSelectingEventStatus } from 'shared/hook'

const InfoBar = () => {
  const selectingEventStatus = useGetSelectingEventStatus()

  if (selectingEventStatus === 'up-coming') return null

  return (
    <div className="w-full bg-chances-bar bg-no-repeat bg-center bg-cover h-14 large:h-[180px] py-2 large:py-6 flex items-center justify-center">
      <CountDownTimer />
      <ChancesBar />
    </div>
  )
}

export default React.memo(InfoBar)
