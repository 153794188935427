import { memo } from 'react'
import Image from 'next/image'

interface DialogHeader {
  headerImage: string
}

const DialogHeader = ({ headerImage }: DialogHeader) => {
  return (
    <div className="relative h-[45px] large:h-[60px] w-full bg-center bg-cover font-shopee text-white text-normal flex items-center justify-center">
      <Image src={headerImage} layout="fill" alt="" draggable={false} />
    </div>
  )
}

export default memo(DialogHeader)
