import React from 'react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { requestInterval } from 'shared/utils'
import { useGetActivityByCodeQuery } from 'store/api.slice'
import { ITimer } from 'types'

const makeTwoDigits = (digit: number) => {
  return digit < 10 ? '0' + digit : digit
}

const calculateTimeLeft = (eventEndTime: number) => {
  const timeToEnd = (eventEndTime - +new Date()) / 1000

  if (timeToEnd < 0)
    return {
      hour: 0,
      minute: 0,
      second: 0,
    }

  return {
    hour: Math.floor(timeToEnd / 3600),
    minute: Math.floor((timeToEnd / 60) % 60),
    second: Math.floor(timeToEnd % 60),
  }
}

const CountDownTimer = () => {
  const { t } = useTranslation()
  const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(0))

  const {
    query: { cid },
  } = useRouter()

  const { data: activityData } = useGetActivityByCodeQuery(cid as string, {
    skip: !cid,
  })

  const cannotGetActivityEndTime = !activityData || !activityData.slots || activityData.slots.length === 0

  useEffect(() => {
    if (cannotGetActivityEndTime) return

    const currentEventEndTime = activityData.slots[0].end_time
    setTimeLeft(calculateTimeLeft(currentEventEndTime))
  }, [activityData])

  useEffect(() => {
    if (cannotGetActivityEndTime) return
    const timeToEnd = activityData.slots[0].end_time - Date.now()
    if (timeToEnd <= 0) return

    const timer: ITimer = requestInterval(() => {
      setTimeLeft(calculateTimeLeft(activityData.slots[0].end_time))
      if (timeToEnd < 0) {
        timer?.clear()
        return
      }
    }, 1000)
    return () => timer?.clear()
  }, [timeLeft])

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <p className="font-sans text-foot large:text-2xl mb-1 large:mb-3">{t('KẾT QUẢ CÔNG BỐ TRONG')}</p>
      <div className="text-small large:text-2xl large:leading-6 font-medium">
        <span className="text-white bg-count rounded-sm px-0.5 large:px-1.5 large:pt-1 large:pb-0.5 mr-px large:mr-1.5">
          {makeTwoDigits(timeLeft.hour)}
        </span>
        <span className="text-normal mr-px large:mr-1.5">:</span>
        <span className="text-white bg-count rounded-sm px-0.5 large:px-1.5 large:pt-1 large:pb-0.5 mr-px large:mr-1.5">
          {makeTwoDigits(timeLeft.minute)}
        </span>
        <span className="text-normal mr-px large:mr-1.5">:</span>
        <span className="text-white bg-count rounded-sm px-0.5 large:px-1.5 large:pt-1 large:pb-0.5 mr-px large:mr-1.5">
          {makeTwoDigits(timeLeft.second)}
        </span>
      </div>
    </div>
  )
}

export default React.memo(CountDownTimer)
