import Image from 'next/image'
import { formatGuessedPrice } from 'shared/utils'
import { memo } from 'react'
import { useSlotConfig } from 'shared/hook'

const GuessedPriceButton = ({ guessedPrice, onClick }) => {
  const slotConfig = useSlotConfig()

  return (
    <div className="relative" onClick={onClick}>
      <div className="absolute top-0 w-8 h-8 large:w-[60px] large:h-[60px]">
        {slotConfig.image_currency && (
          <Image src={slotConfig.image_currency} width="32" height="32" layout="responsive" alt="" />
        )}
      </div>
      <div className="border border-primary h-[32px] large:h-[60px] ml-4 large:ml-8 pl-5 large:pl-12 rounded-r-md flex items-center text-large text-primary font-medium large:text-3xl">
        {formatGuessedPrice(guessedPrice)}
      </div>
    </div>
  )
}

export default memo(GuessedPriceButton)
