import GeneralDialog from 'dialogs/GeneralDialog'
import { memo, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { DialogType } from 'store/dialogs.slice'
import { useDialogs, useSlotConfig } from 'shared/hook'
import apiSlice, { useAddToCartMutation, useGetUserChanceQuery, useGuessPriceMutation } from 'store/api.slice'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store'
import {
  getCFImage,
  getImage,
  getOriginalPrice,
  getShopeeItemUrl,
  numberFormatter,
  parseTextWithItemName,
  requestTimeout,
  showToast,
  simpleHash,
} from 'shared/utils'
import Image from 'next/image'
import { IItemData, ITimer } from 'types'
import InputNumber from 'rc-input-number'
import styles from './GuessPrice.module.scss'
import clsx from 'clsx'
import { IAxiosBaseQueryError } from 'shared/axios-settings'
import { IReminderData, micrositeReminder } from 'shared/webBridge'
import { addReminder } from 'store/app.slice'

const formatterNumber = (val?: string) => {
  if (!val) return ''
  return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.').replace(/\.(?=\d{0,2}$)/g, ',')
}

const parserNumber = (val?: string) => {
  if (!val) return ''
  return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')).toFixed(2)
}

const GuessPriceDialog = () => {
  const { query } = useRouter()
  const [inputValue, setInputValue] = useState<string>('')
  const eventCode = useSelector((state: RootState) => state.app.selectingEvent) as string
  const autoRemindToBuy = useSelector((state: RootState) => state.app.autoRemindToBuy)
  const reminderList = useSelector((state: RootState) => state.app.reminderList)
  const activityCode = query.cid as string
  const dispatch = useAppDispatch()
  const { isOpen, close, payload } = useDialogs(DialogType.GuessPriceDialog)
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const { data: userChance } = useGetUserChanceQuery({ activityCode, eventCode }, { skip: !isOpen })
  const [guessPrice] = useGuessPriceMutation()
  const totalChance = userChance?.total_balance || 0
  const slotConfig = useSlotConfig()
  const { open: openOutOfChanceDialog } = useDialogs(DialogType.OutOfChanceDialog)
  const [isLoading, setIsLoading] = useState(false)
  const [inputAnimated, setInputAnimated] = useState(false)
  const [addToCart] = useAddToCartMutation()
  const itemData = (payload || {}) as IItemData
  const { mallData } = itemData

  useEffect(() => {
    let timeoutId: ITimer

    if (isOpen) {
      setInputValue('')
      timeoutId = requestTimeout(() => {
        inputRef?.current?.focus()
      }, 250)
    } else {
      inputRef?.current?.blur()
    }

    return () => timeoutId?.clear()
  }, [isOpen])

  useEffect(() => {
    if (itemData.user_guessed_price && isOpen) {
      setInputValue(`${itemData.user_guessed_price}`)
    }
    if (isOpen) setIsLoading(false)
  }, [itemData.user_guessed_price, isOpen])

  useEffect(() => {
    if (!inputAnimated) return

    const timer: ITimer = requestTimeout(() => setInputAnimated(false), 300)

    return () => timer?.clear()
  }, [inputAnimated])

  const handleGuessPrice = async () => {
    if (isLoading) return null

    setIsLoading(true)

    const { error } = (await guessPrice({
      eventCode,
      activityCode,
      itemUid: itemData.item_uid as number,
      price: +inputValue,
    })) as Record<string, IAxiosBaseQueryError>

    if (error) {
      if (error.data.code === 4009) {
        close()
        dispatch(apiSlice.util.invalidateTags(['Chance']))
        openOutOfChanceDialog()
      } else showToast({ message: t('Đã có lỗi xảy ra. Vui lòng thử lại'), iconType: 'failure' })

      setIsLoading(false)
      return
    }

    let toastMessage = itemData.user_guessed_price ? t('Sửa giá đoán thành công') : t('Nhập giá đoán thành công')

    if (autoRemindToBuy && itemData.display_reminder) {
      const remindBuyData = {
        reminderId: itemData.item_uid,
        title: parseTextWithItemName(itemData.text_remind_to_buy_title, mallData?.name),
        content: parseTextWithItemName(itemData.text_remind_to_buy_content, mallData?.name),
        startTime: Math.round((itemData.time_to_remind || 0) / 1000),
        endTime: Math.round((itemData.time_to_remind || 0) / 1000) + 86400,
        redirectPath: itemData.text_reminder_url || getShopeeItemUrl(itemData.shop_id, itemData.item_id),
      } as IReminderData
      //TODO: Should bring into hook for better reusibility
      const hashData = {
        remindType: 'buy',
        startTime: remindBuyData.startTime,
        reminderId: remindBuyData.reminderId,
        redirectPath: remindBuyData.redirectPath,
        title: remindBuyData.title,
        content: remindBuyData.content,
      }
      const hash = simpleHash(JSON.stringify(hashData))
      const isReminded = reminderList.some((reminder) => reminder.hash === hash)
      if (!isReminded) {
        requestTimeout(async () => {
          if (!document.referrer) {
            //* mean it should be in Shopee App, not in microsite
            await micrositeReminder(remindBuyData)
            dispatch(addReminder({ beginTime: remindBuyData.startTime * 1000, hash }))
          }
        }, 3000)
      }
      if (!itemData.added_to_cart) {
        const { error } = (await addToCart({ item_uid: itemData.item_uid as number })) as Record<
          string,
          IAxiosBaseQueryError
        >
        if (!error) toastMessage = `${toastMessage}. ${t('Sản phẩm đã được thêm vào giỏ hàng của bạn')}`
      }
    }

    showToast({
      message: toastMessage,
      iconType: 'success',
    })

    close()
  }

  const renderPrice = (price: number) => {
    return (
      <>
        <span className="underline">{t('đ')}</span>
        {numberFormatter(price)}
      </>
    )
  }

  return (
    <GeneralDialog
      onClose={() => {
        close()
      }}
      open={isOpen}
      primaryActionProps={{
        children: !itemData.user_guessed_price ? t`Đoán giá` : t`Sửa giá`,
        disabled: !inputValue || +inputValue === itemData.user_guessed_price || isLoading,
      }}
      secondaryActionProps={{
        children: !itemData.user_guessed_price ? t`Đóng` : t`Huỷ`,
      }}
      onClickPrimary={handleGuessPrice}
    >
      <div className="relative h-[120px] large:h-[170px] w-full flex justify-center items-center">
        <Image
          src={getCFImage({ url: slotConfig?.image_header_input_price }) || getImage('guess-price.png')}
          alt=""
          layout="fill"
        />
      </div>
      <div className="pt-4 px-5 flex flex-col items-center text-sm large:text-title">
        <div>{t`Đoán giá sau khi giảm cho sản phẩm`}</div>
        <div className="font-bold text-center line-clamp-2">{mallData?.name as string}</div>
        <div className="opacity-50">
          {t`Giá gốc: `}
          {renderPrice(getOriginalPrice(mallData) / 1e5)}
        </div>
        <div className="relative w-full flex mt-2.5 mb-2">
          <div className="absolute top-0">
            {slotConfig.image_currency && <Image src={slotConfig.image_currency} width="36" height="36" alt="" />}
          </div>
          <div
            className={`flex-1 text-center border border-primary ml-5 pr-5 h-[36px] rounded-r-md flex items-center text-large text-primary font-medium`}
          >
            <InputNumber
              min={'0'}
              max={'99999999999'}
              className={clsx(styles['input-price'], inputAnimated && styles['animated'])}
              ref={inputRef}
              placeholder={'???'}
              value={inputValue}
              onChange={setInputValue}
              inputMode="numeric"
              controls={false}
              formatter={formatterNumber}
              parser={parserNumber}
            />
          </div>
        </div>
        <div className="text-small large:text-title text-center">
          <Trans count={totalChance}>
            Bạn còn <strong>{{ totalChance }}</strong> lượt đoán
          </Trans>
        </div>
        <div className="flex items-center w-full my-3">
          <div className="flex-1 h-[1px] bg-black opacity-10" />
          <div className="px-2">{t`Gợi ý:`}</div>
          <div className="flex-1 h-[1px] bg-black opacity-10" />
        </div>
        <div className="flex justify-between items-center w-full mb-5">
          {itemData.price_recommend_list?.map((item) => {
            return (
              <div
                key={item}
                className="cursor-pointer w-[29%] rounded-full bg-black bg-opacity-10 py-1 text-xs large:text-base text-center"
                onClick={() => setInputValue(`${item}`)}
              >
                {renderPrice(item)}
              </div>
            )
          })}
        </div>
      </div>
    </GeneralDialog>
  )
}

export default memo(GuessPriceDialog)
