import clsx from 'clsx'
import Image from 'next/image'
import { memo, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { getImage } from 'shared/utils'

interface TaskItemProps {
  title: string
  chancePrize: number
  logo: string
  actionText: string
  isDone: boolean
  onClick: () => void
}

const TaskItem = ({ title, chancePrize, logo, actionText, isDone, onClick }: TaskItemProps) => {
  const { t } = useTranslation()

  const handleOnClick = (ev: MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation()
    onClick()
  }

  return (
    <div
      onClick={handleOnClick}
      className="flex justify-between items-center py-1.5 large:py-2.5 px-3.5 large:px-4.5 border-t border-line"
    >
      <div className={clsx('flex flex-1', { 'opacity-50': isDone })}>
        <div className="relative w-9 min-w-[36px] large:w-10 large:min-w-[40px] h-9 large:h-10 mr-4 large:mr-3">
          <Image className="rounded border-line" src={logo} alt="" draggable={false} layout="fill" />
        </div>
        <div>
          <p className="font-medium text-normal large:text-large mb-0.5 large:mb-1 line-clamp-1">{title}</p>
          <div className="flex items-start">
            <Image src={getImage('gift-box.png')} alt="" draggable={false} width={14} height={14} />
            <p className="text-small large:text-normal ml-1 large:ml-1.5">
              {t('Nhận {{count}} lượt đoán', { count: chancePrize })}
            </p>
          </div>
        </div>
      </div>
      {isDone ? (
        <p className="text-success text-small large:text-normal">{t('Đã hoàn thành')}</p>
      ) : (
        <button className="bg-primary text-white min-w-[70px] min-h-[24px] rounded-sm text-small large:text-normal large:min-w-[90px] large:min-h-[30px]">
          {actionText}
        </button>
      )}
    </div>
  )
}

export default memo(TaskItem)
