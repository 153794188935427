import GeneralDialog from 'dialogs/GeneralDialog'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDialogs } from 'shared/hook'
import { DialogType } from 'store/dialogs.slice'
import Image from 'next/image'
import { getImage } from 'shared/utils'

const OutOfChanceDialog = () => {
  const { isOpen, close } = useDialogs(DialogType.OutOfChanceDialog)
  const { t } = useTranslation()
  const { open: openTaskDialog } = useDialogs(DialogType.TaskDialog)

  return (
    <GeneralDialog
      onClose={close}
      open={isOpen}
      primaryActionText={t('Thêm lượt ngay')}
      onClickPrimary={() => {
        close()
        openTaskDialog()
      }}
    >
      <div className="p-5 large:p-9 flex justify-center items-center flex-col text-center text-normal large:text-title large:w-[470px]">
        <div className="relative h-[120px] large:h-[200px] w-[120px] large:w-[200px] mb-5 large:my-10">
          <Image src={getImage('out-of-chance.png')} alt="" layout="fill" />
        </div>
        <p className="font-bold mb-2.5 large:mb-4">{t('Bạn đã hết lượt đoán giá')}</p>
        <p className="mb-5 max-w-[350px]">{t('Thực hiện thử thách để nhận thêm lượt đoán giá nhé!')}</p>
      </div>
    </GeneralDialog>
  )
}

export default memo(OutOfChanceDialog)
