import React from 'react'
import Image from 'next/image'
import { getCFImage, getImage } from 'shared/utils'
import { Trans, useTranslation } from 'react-i18next'
import apiSlice, { useGetUserChanceQuery, useGetActivityByCodeQuery } from 'store/api.slice'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store'
import { micrositeLogin } from 'shared/webBridge'
import { useRouter } from 'next/router'
import { DialogType } from 'store/dialogs.slice'
import { useDialogs } from 'shared/hook'

const ChancesBar = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const dispatch = useAppDispatch()
  const {
    isReady,
    query: { cid, site },
  } = router
  const isLogin = useSelector((state: RootState) => state.app.isLogin)
  const selectingEvent = useSelector((state: RootState) => state.app.selectingEvent) as string
  //TODO: move it to hook for better reusibility
  const { data: chancesData } = useGetUserChanceQuery(
    {
      eventCode: selectingEvent,
      activityCode: cid as string,
    },
    { skip: !isReady || !cid || !selectingEvent },
  )
  const { data: activityData } = useGetActivityByCodeQuery(cid as string, {
    skip: !isReady || !cid,
  })
  const { open: openTaskDialog } = useDialogs(DialogType.TaskDialog)

  return (
    <div className="w-full h-full flex flex-col items-center justify-center border-l border-line">
      {isLogin ? (
        <>
          <div className="flex items-center mb-1">
            <div className="flex items-center w-4 h-4 large:w-10 large:h-10 relative">
              <Image
                src={
                  activityData
                    ? getCFImage({
                        url: activityData.slots[0].config.image_currency,
                        isTiny: true,
                      })
                    : getImage('dong.png')
                }
                layout="fill"
                alt=""
              />
            </div>
            <p className="text-small large:text-2xl large:leading-6 ml-1 large:ml-4">
              <Trans count={chancesData?.total_balance}>
                Bạn còn <span className="font-bold">{{ count: chancesData?.total_balance ?? 0 }}</span> lượt đoán.
              </Trans>
            </p>
          </div>
          <p
            className="text-small large:text-2xl large:leading-6 text-primary font-bold"
            onClick={() => openTaskDialog()}
          >
            {t('Thêm lượt')}
          </p>
        </>
      ) : (
        <p
          onClick={() =>
            micrositeLogin(site as string, () => {
              dispatch(apiSlice.util.invalidateTags(['Chance']))
            })
          }
          className="text-small large:text-2xl large:leading-6 text-primary font-bold"
        >
          {t('Đăng nhập ngay')}
        </p>
      )}
    </div>
  )
}

export default React.memo(ChancesBar)
