import DialogHeader from 'dialogs/DialogHeader'
import GeneralDialog from 'dialogs/GeneralDialog'
import { memo, useEffect, useState } from 'react'
import Loop from 'icons/Loop'
import { useTranslation, Trans } from 'react-i18next'
import { DialogType } from 'store/dialogs.slice'
import { useDialogs } from 'shared/hook'
import SwipeableViews from 'react-swipeable-views'
import TaskItem from 'components/TaskItem'
import clsx from 'clsx'
import { useGetTasksQuery, useGetUserChanceQuery, useGetActivityByCodeQuery } from 'store/api.slice'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  executeTask,
  getCFImage,
  getImage,
  getTaskActionText,
  handleShareNavigation,
  isLargeScreen,
  requestTimeout,
  showToast,
} from 'shared/utils'
import { getPersistentDialog, removePersistentDialog } from 'shared/persistent'
import { ITimer } from 'types'

const TaskDialog = () => {
  const {
    isReady,
    query: { cid },
  } = useRouter()
  const eventCode = useSelector((state: RootState) => state.app.selectingEvent) as string
  const { open, isOpen, close } = useDialogs(DialogType.TaskDialog)
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(true)

  const {
    data: taskData,
    error: taskError,
    refetch: refetchTasks,
  } = useGetTasksQuery({ activityCode: cid as string, eventCode }, { skip: !isOpen || !cid || !eventCode })

  const { data: userChance, refetch: refetchUserChance } = useGetUserChanceQuery(
    { activityCode: cid as string, eventCode },
    { skip: !isOpen || !cid || !eventCode },
  )

  const { data: activityData } = useGetActivityByCodeQuery(cid as string, {
    skip: !isReady || !cid,
  })

  const numberOfView = taskData?.tasks ? Math.ceil((taskData.tasks.length + 1) / 5) : 1

  const [currentView, setCurrentView] = useState(0)
  const [loopSpin, setLoopSpin] = useState(false)

  const handleChangeView = () => {
    const nextView = currentView + 1 > numberOfView - 1 ? 0 : currentView + 1
    setCurrentView(nextView)
    setLoopSpin(true)
  }

  // refetch the tasks every time we open task dialog again
  useEffect(() => {
    if (isOpen) refetchTasks()
  }, [isOpen])

  useEffect(() => {
    // for some devices, when back from other pages, it don't reload, so need this to manually refetch the data
    let timer: ITimer
    if (isOpen && isVisible) {
      // need to wait 1000ms because some devices need time before actual reload
      timer = requestTimeout(() => {
        refetchTasks()
        refetchUserChance()
      }, 1000)
    }

    return () => timer?.clear()
  }, [isVisible])

  useEffect(() => {
    if (taskData?.prize_awarded) {
      refetchUserChance()
      const totalPrize = taskData.prize_awarded.find((prize) => prize.type === 'prize_chance')?.quantity || 0
      showToast({ message: t('Bạn đã nhận {{count}} lượt đoán giá', { count: totalPrize }), iconType: 'success' })
      removePersistentDialog()
    } else if (taskError) {
      showToast({ message: t('Có lỗi xảy ra. Vui lòng thử lại!'), iconType: 'failure' })
      removePersistentDialog()
    }
  }, [taskData, taskError])

  useEffect(() => {
    if (!eventCode) return
    if (!getPersistentDialog()) return
    if (getPersistentDialog().dialog === DialogType.TaskDialog) {
      open()
      if (getPersistentDialog().willRemovePersistentOnOpen) {
        removePersistentDialog()
      }
    }
    const visibilityControl = () => setIsVisible(document.visibilityState === 'visible')
    document.addEventListener('visibilitychange', visibilityControl)

    return () => document.removeEventListener('visibilitychange', visibilityControl)
  }, [eventCode])

  return (
    <GeneralDialog
      onClose={() => {
        removePersistentDialog()
        setCurrentView(0)
        close()
      }}
      open={isOpen}
      hidePrimaryAction={numberOfView === 1}
      onClickPrimary={handleChangeView}
      hideSecondaryAction
      showCrossClose
      backdropClickable
      primaryActionText={
        <div className="flex justify-center items-center">
          <Loop className={clsx('h-4 w-4 large:w-5 large:h-5', { 'animate-spin-0.3': loopSpin })} />
          &nbsp;{t('Xem nhiệm vụ khác')}
        </div>
      }
    >
      <DialogHeader
        headerImage={
          activityData?.slots?.[0]
            ? getCFImage({
                url: activityData.slots[0].config.image_header_task,
              })
            : getImage('task-header.png')
        }
      />
      <p className="text-small large:text-sm py-2.5 large:py-3.5 text-center">
        <Trans count={userChance?.total_balance}>
          Bạn còn <span className="font-bold">{{ count: userChance?.total_balance ?? 0 }}</span> lượt đoán giá
        </Trans>
      </p>
      <SwipeableViews
        className={clsx('h-[245px] large:h-[306px]', { 'mb-2': numberOfView === 1 })}
        index={currentView}
        onTransitionEnd={() => setLoopSpin(false)}
        disabled
      >
        {Array.from(Array(numberOfView)).map((_, idx) => {
          const startIdx = idx === 0 ? 0 : 5 * idx - 1
          const endIdx = idx === 0 ? 4 : startIdx + 5

          return (
            <div key={idx}>
              {idx === 0 && (
                <TaskItem
                  title={t('Chia sẻ để sửa sai')}
                  logo={getImage('share-logo.png')}
                  chancePrize={userChance?.chances_each_sharing || 0}
                  actionText={t('Chia sẻ')}
                  isDone={!userChance?.remaining_share_times}
                  onClick={() => handleShareNavigation('event')}
                />
              )}
              {taskData?.tasks?.slice(startIdx, endIdx).map((task) => (
                <TaskItem
                  key={task.id}
                  title={task.task_name}
                  logo={getCFImage({ url: task.assets.task_logo, isHash: true, isTiny: !isLargeScreen() })}
                  chancePrize={task.prize_quantity}
                  actionText={getTaskActionText(task.action_type)}
                  isDone={task.status === 'COMPLETED'}
                  onClick={() => executeTask(task)}
                />
              ))}
            </div>
          )
        })}
      </SwipeableViews>
    </GeneralDialog>
  )
}

export default memo(TaskDialog)
