import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useGetItemListData, useGetSelectingEventStatus } from 'shared/hook'
import { RootState, useAppDispatch } from 'store'
import { setAutoRemindToBuy } from 'store/app.slice'
import styles from './RemindCheckBox.module.scss'

const RemindCheckBox = () => {
  const { t } = useTranslation()
  const autoRemindToBuy = useSelector((state: RootState) => state.app.autoRemindToBuy)
  const dispatch = useAppDispatch()

  const selectingEventStatus = useGetSelectingEventStatus()
  const itemListData = useGetItemListData()

  const shouldShowRemindCheckBox = useMemo(() => {
    return itemListData?.some((itemData) => itemData.display_reminder === true) && selectingEventStatus === 'on-going'
  }, [itemListData, selectingEventStatus])

  return (
    <label className={clsx({ invisible: !shouldShowRemindCheckBox }, styles.container)}>
      <input
        type="checkbox"
        checked={autoRemindToBuy}
        onChange={(e) => dispatch(setAutoRemindToBuy(e.target.checked))}
      />
      <span className={styles.checkmark}></span>
      {t('Thêm vào giỏ hàng và nhắc mua khi sản phẩm tôi đoán có giảm giá sốc')}
    </label>
  )
}

export default RemindCheckBox
