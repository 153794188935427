const EventTabsSkeleton = () => {
  return (
    <div className="bg-white flex justify-between items-center border-b">
      {Array.from(Array(4)).map((item, idx) => (
        <div key={idx} className="flex-1 flex flex-col justify-center items-center pt-3 pb-1 large:pt-7 large:pb-7">
          <div className="w-9 h-4 large:w-[116px] large:h-9 bg-skeleton animate-pulse" />
          <div className="w-12 h-3 large:w-[160px] large:h-6 bg-skeleton animate-pulse mt-1 large:mt-2" />
        </div>
      ))}
    </div>
  )
}

export default EventTabsSkeleton
