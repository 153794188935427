import clsx from 'clsx'
import ItemCard from 'components/ItemCard'
import { useRef } from 'react'
import styles from './ItemList.module.scss'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { iOSversion, isLargeScreen } from 'shared/utils'
import CarouselArrows from 'components/CarouselArrows'
import ItemListSkeleton from 'components/ItemListSkeleton'
import { useGetItemListData } from 'shared/hook'
import { isIOS } from 'shared/webBridge'

export const CARD_WIDTH_WITH_MARGIN = isLargeScreen() ? 473 : 178

const ItemList = () => {
  const listRef = useRef<HTMLDivElement>(null)
  const selectingEvent = useSelector((state: RootState) => state.app.selectingEvent)

  const itemListData = useGetItemListData()

  if (!selectingEvent || !itemListData) return <ItemListSkeleton />

  return (
    <div className="relative">
      <div ref={listRef} className={clsx(styles.list, 'flex overflow-x-hidden justify-between py-4 large:py-7')}>
        {itemListData?.map((itemData, idx) => (
          <ItemCard key={idx} itemIdx={idx} itemData={itemData} />
        ))}
      </div>
      <CarouselArrows
        key={selectingEvent}
        listLength={itemListData?.length || 0}
        onChange={(position: number) => {
          listRef?.current?.scrollTo({
            left: position * CARD_WIDTH_WITH_MARGIN,
            behavior: isIOS() && iOSversion() >= 15.4 ? 'auto' : 'smooth',
          })
        }}
      />
    </div>
  )
}

export default ItemList
