import ItemList from 'components/ItemList'
import { useEffect, useRef } from 'react'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { appApiAxios, IAxiosBaseQueryError } from 'shared/axios-settings'
import { RootState, useAppDispatch } from 'store'
import apiSlice, { useGetActivityByCodeQuery } from 'store/api.slice'
import { requestTimeout, setTimeOffset } from 'shared/utils'
import { useSelector } from 'react-redux'
import ErrorScreen from 'components/ErrorScreen'
import { setCurrentEventStatus, setReminderList, setSelectingEvent } from 'store/app.slice'
import { getPersistentReminderList } from 'shared/persistent'
import { EventStatus, ITimer } from 'types'
import EventTabs from 'components/EventTabs'
import InfoBar from 'components/InfoBar'
import Toast from 'components/Toast'
import GuessPriceDialog from 'dialogs/GuessPriceDialog'
import TaskDialog from 'dialogs/TaskDialog'
import OutOfChanceDialog from 'dialogs/OutOfChanceDialog'
import EndedTimeDialog from 'dialogs/EndedTimeDialog'
import { useDialogs } from 'shared/hook'
import { DialogType } from 'store/dialogs.slice'
import RemindCheckBox from 'components/RemindCheckBox'

const Home: NextPage = () => {
  const router = useRouter()
  const {
    query: { cid },
    isReady,
  } = router
  const dispatch = useAppDispatch()
  const selectingEvent = useSelector((state: RootState) => state.app.selectingEvent)
  const currentEventStatus = useSelector((state: RootState) => state.app.currentEventStatus)
  const { open: openEndedTimeDialog } = useDialogs(DialogType.EndedTimeDialog)

  const { data, error } = useGetActivityByCodeQuery(cid as string, {
    skip: !cid,
    // refetchOnFocus: true,
  })

  const prevEventStatus = useRef<EventStatus | null>()
  useEffect(() => {
    if (prevEventStatus.current === 'up-coming' && currentEventStatus === 'on-going') {
      dispatch(apiSlice.util.invalidateTags(['Activity', 'Chance']))
    }

    if (prevEventStatus.current === 'on-going' && currentEventStatus === 'ended') {
      openEndedTimeDialog()
    }

    prevEventStatus.current = currentEventStatus
  }, [currentEventStatus])

  useEffect(() => {
    if (!isReady) return

    if (!cid) router.replace('/end')
  }, [isReady])

  useEffect(() => {
    if (!error) return

    const { status, data } = error as IAxiosBaseQueryError
    if (status === 404 && data?.code === 3001) router.replace('/end')
    //TODO: handle error if cannot get the activity data
  }, [error])

  useEffect(() => {
    let timer: ITimer

    if (data) {
      if (!data.slots || data.slots.length === 0) {
        router.replace('/end')
        return
      }

      //* if we don't have `selecting event` or `selecting event` is no longer available, set selectingEvent as `current event code`
      if (!selectingEvent || !data.slots.map((slot) => slot.event_code).includes(selectingEvent as string)) {
        dispatch(setSelectingEvent(data.current_event_code))
      }

      const currentEventData = data.slots[0]
      const timeToOngoing = currentEventData.begin_time - Date.now()
      const timeToEnd = currentEventData.end_time - Date.now()

      if (timeToOngoing > 0) {
        dispatch(setCurrentEventStatus('up-coming'))
        timer = requestTimeout(() => {
          dispatch(setCurrentEventStatus('on-going'))
        }, timeToOngoing)
      } else if (timeToEnd >= 0) {
        dispatch(setCurrentEventStatus('on-going'))
        timer = requestTimeout(() => {
          dispatch(setCurrentEventStatus('ended'))
        }, timeToEnd)
      } else dispatch(setCurrentEventStatus('ended'))
    }

    return () => {
      timer?.clear()
    }
  }, [data, currentEventStatus])

  useEffect(() => {
    //* Get server time and set to offset
    appApiAxios.get('/delta/time/').then((res) => setTimeOffset(res.data.data?.['unix_ts']))

    dispatch(setReminderList(getPersistentReminderList()))
  }, [])

  return (
    <main className="h-screen flex flex-col">
      <EventTabs data={data} />
      <InfoBar />
      <ItemList key={selectingEvent} />
      <RemindCheckBox />
      <TaskDialog />
      <OutOfChanceDialog />
      <GuessPriceDialog />
      <EndedTimeDialog />
      <ErrorScreen />
      <Toast />
    </main>
  )
}

export default Home
