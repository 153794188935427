import GuessedPriceButton from 'components/GuessedPriceButton'
import RemindButton from 'components/RemindButton'
import MallBadge from 'icons/MallBadge'
import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import {
  formatGuessedPrice,
  getCFImage,
  getImage,
  getMicrositeUniversalUrl,
  getOriginalPrice,
  getShopeeItemUrl,
  isLargeScreen,
  numberFormatter,
  timeOffset,
} from 'shared/utils'
import { RootState, useAppDispatch } from 'store'
import { DialogType } from 'store/dialogs.slice'
import { micrositeLogin, micrositeNavigate } from 'shared/webBridge'
import { useSelector } from 'react-redux'
import apiSlice, { useGetUserChanceQuery } from 'store/api.slice'
import { useRouter } from 'next/router'
import { useDialogs, useGetRemindData, useGetSelectingEventStatus } from 'shared/hook'
import { IItemData } from 'types'

export interface ItemCardProps {
  itemData: IItemData
  itemIdx: number
}

const ItemCard = ({ itemData, itemIdx }: ItemCardProps) => {
  const { query, isReady } = useRouter()
  const { cid, site } = query
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const selectingEvent = useSelector((state: RootState) => state.app.selectingEvent)
  const selectingEventStatus = useGetSelectingEventStatus()
  const isLogin = useSelector((state: RootState) => state.app.isLogin)
  const { data: chancesData } = useGetUserChanceQuery(
    {
      eventCode: selectingEvent as string,
      activityCode: cid as string,
    },
    { skip: !isReady || !cid || !selectingEvent || selectingEventStatus !== 'on-going' },
  )
  const { open: openOutOfChanceDialog } = useDialogs(DialogType.OutOfChanceDialog)
  const { open: openGuessPriceDialog } = useDialogs(DialogType.GuessPriceDialog)
  const remindData = useGetRemindData()
  const { mallData } = itemData

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    if (!isLogin) {
      micrositeLogin(site as string, () => {
        dispatch(apiSlice.util.invalidateTags(['Slot']))
      })
    } else if (!chancesData?.total_balance) {
      openOutOfChanceDialog()
    } else openGuessPriceDialog({ payload: itemData })
  }

  const handleItemClick = () => {
    micrositeNavigate(getShopeeItemUrl(itemData?.shop_id, itemData?.item_id))
  }

  return (
    <div
      className="border min-w-[170px] w-[170px] large:min-w-[455px] large:w-[455px] mx-1 large:m-[9px] flex flex-col"
      onClick={handleItemClick}
    >
      <div className="relative h-[168px] large:h-[455px]">
        {mallData?.image && (
          <Image
            objectFit="cover"
            layout="fill"
            src={getCFImage({ url: mallData?.image, isHash: true, isTiny: !isLargeScreen() })}
            width={455}
            height={455}
            alt=""
            draggable={false}
          />
        )}

        {!!itemData.user_guessed_price && (
          <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50">
            <div className="relative w-[108px] h-[84px] large:w-[278px] large:h-[216px]">
              <Image objectFit="cover" layout="fill" src={getImage('guessed_price.png')} alt="" draggable={false} />
            </div>
          </div>
        )}
        {mallData?.is_official_shop && (
          <div className="absolute top-1 -left-1 large:top-3 large:-left-2 w-[26px] h-[15px] large:w-[73px] large:h-[42px]">
            <MallBadge />
          </div>
        )}
      </div>

      <div className="p-2 large:p-5">
        <p className="text-normal large:text-2xl leading-5 large:leading-7 line-clamp-2 h-[40px] large:h-[60px] mb-1 large:mb-4">
          {mallData?.name}
        </p>

        <div className="flex mb-1 large:mb-4 items-center">
          <p className="pr-1 large:pr-5 text-gray-400 text-foot large:text-2xl">{t('Giá gốc:')}</p>
          <div className="text-small large:text-2xl flex">
            <p className="underline">{t('đ')}</p>
            {
              //* must divide by 1e5 because the API return with redundant 5 zeros
              //TODO: apply this lib https://confluence.shopee.io/display/SPDTID/Currency
              numberFormatter(getOriginalPrice(mallData) / 1e5)
            }
          </div>
        </div>

        <div className="flex mb-1 large:mb-4 items-center">
          <p className="pr-1 large:pr-5 text-gray-400 text-foot large:text-2xl">{t('Giá bạn đoán:')}</p>
          <div className="text-small large:text-2xl text-primary flex h-[14px] large:h-[28px]">
            {selectingEventStatus === 'up-coming' && (
              <>
                <p className="underline">{t('đ')}</p>
                {formatGuessedPrice(itemData.user_guessed_price)}
              </>
            )}
          </div>
        </div>

        {selectingEventStatus === 'up-coming' ? (
          <RemindButton
            remindType="event"
            itemName={mallData?.name}
            reminderId={remindData.beginTime - timeOffset + itemIdx}
            redirectPath={getMicrositeUniversalUrl(query.site as string)}
            {...remindData}
          />
        ) : (
          <GuessedPriceButton guessedPrice={itemData.user_guessed_price} onClick={handleClick} />
        )}
      </div>
    </div>
  )
}

export default ItemCard
